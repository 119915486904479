import { createSlice } from '@reduxjs/toolkit';

export const APPSlice = createSlice({
    name: 'APP',
    initialState: {
        appError: [],
        leftDrawerOpen: false,
        drawerWidth: 456
    },
    reducers: {
        setAppError: (state, action) => {
            state.appError = action.payload;
        },
        setLeftDrawerOpen: (state, action) => {
            state.leftDrawerOpen = action.payload;
        },
        setDrawerWidth: (state, action) => {
            state.drawerWidth = action.payload;
        },

    },
});

export const { setAppError, setLeftDrawerOpen, setDrawerWidth } = APPSlice.actions;

export const getAppError = state => state.APP.appError;
export const getLeftDrawerOpen = state => state.APP.leftDrawerOpen;
export const getDrawerWidth = state => state.APP.drawerWidth;

export default APPSlice.reducer;
