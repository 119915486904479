import { createSlice } from "@reduxjs/toolkit";

export const TREESlice = createSlice({
    name: "TREE",
    initialState: {
        treeData: [],
        selectedTreeItem: localStorage.getItem("tree.selected")
            ? JSON.parse(localStorage.getItem("tree.selected"))
            : null,
        expandedItems: localStorage.getItem("tree.expanded") ? JSON.parse(localStorage.getItem("tree.expanded")) : [],
        onTopId: localStorage.getItem("tree.top") ? JSON.parse(localStorage.getItem("tree.top")) : null,
        scrolledItem: null,
    },
    reducers: {
        setTreeData: (state, action) => {
            state.treeData = action.payload;
        },
        setSelectedTreeItem: (state, action) => {
            localStorage.setItem("tree.selected", JSON.stringify(action.payload));
            state.selectedTreeItem = action.payload;
        },
        setExpandedItems: (state, action) => {
            localStorage.setItem("tree.expanded", JSON.stringify(action.payload));
            state.expandedItems = action.payload;
        },
        setOnTopId: (state, action) => {
            // localStorage.setItem('tree.top', JSON.stringify(action.payload))
            state.onTopId = action.payload;
        },
        setScrolledItem: (state, action) => {
            state.scrolledItem = action.payload;
        },
    },
});

export const { setTreeData, setSelectedTreeItem, setExpandedItems, setOnTopId, setScrolledItem } = TREESlice.actions;

export const getTreeData = (state) => state.TREE.treeData;
export const getSelectedTreeItem = (state) => state.TREE.selectedTreeItem;
export const getExpandedItems = (state) => state.TREE.expandedItems;
export const getOnTopId = (state) => state.TREE.onTopId;
export const getScrolledItem = (state) => state.TREE.scrolledItem;

export default TREESlice.reducer;
