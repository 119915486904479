import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import "./components/common/i18n";

const Routes = React.lazy(() => import("./routes"));

function App() {
    const { enableLinkTracking } = useMatomo();
    enableLinkTracking();
    return (
        <Suspense fallback={<div>Ładowanie...</div>}>
            <Routes />
        </Suspense>
    );
}

export default App;
