import { createTheme } from "@material-ui/core/styles";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#142F4E",
      light: "#6677A1",
      lighter: "#017AC9",
    },
    secondary: {
      main: "#b9d330",
    },

    background: {
      main: "#F8F9FA",
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: "Open Sans, sans-serif",
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 300,
      fontSize: "6rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 300,
      fontSize: "3.75rem",
      lineHeight: 1.2,
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 300,
      fontSize: "3rem",
      lineHeight: 1.167,
      letterSpacing: "0em",
    },
    h4: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 300,
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
    },
    h5: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 300,
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em",
    },
    h6: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
    subtitle1: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.75,
      letterSpacing: "0.00938em",
    },
    subtitle2: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.57,
      letterSpacing: "0.00714em",
    },
    body1: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    body2: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.43,
      letterSpacing: "0.01071em",
    },
    button: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      textTransform: "uppercase",
      "&:hover": {
        backgroundColor: "#142F4E",
      },
    },
    caption: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: "0.03333em",
    },
    overline: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 2.66,
      letterSpacing: "0.08333em",
      textTransform: "uppercase",
    },
  },
});

const { breakpoints } = defaultTheme;

const theme = {
  ...defaultTheme,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          fontSize: 16,
          [breakpoints.down("sm")]: {
            fontSize: "0.875rem",
          },
          [breakpoints.down("xs")]: {
            fontSize: "0.75rem",
          },
          WebkitFontSmoothing: "auto",
          minHeight: "100vh",
          padding: 0,
          margin: 0,
        },
        body: {
          fontFamily: "Open Sans, sans-serif",
          padding: "0!important",
          margin: 0,
          fontSize: 16,
          fontWeight: 400,
          background: "#F8F9FA",
          overflow: "visible!important",
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 23,
      },
    },

    MuiDivider: {
      root: {
        backgroundColor: "#CCC",
      },
    },
  },
  shape: {
    ...defaultTheme.shape,
    boxRadius: 20,
    boxedWrapper: {
      backgroundColor: "#FFF",
      boxShadow: "3px 9px 35px #0000000D",
      padding: defaultTheme.spacing(2),
      borderRadius: 20,
      border: "0.5px solid #D2D6DA",
      // [defaultTheme.breakpoints.down('xs')]:{
      //     padding: defaultTheme.spacing(1, 2),
      // borderRadius: 20,
      // }
    },
  },
};

export default theme;
