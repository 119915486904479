import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

import reportWebVitals from "./reportWebVitals";

import App from "./App";
import store from "./_store";
import theme from "./theme";

const matomoInstance = createInstance({
    urlBase: "https://stats.stat.gov.pl",
    siteId:
        process?.env?.REACT_APP_MATOMO_IDSITE && !isNaN(Number(process?.env?.REACT_APP_MATOMO_IDSITE))
            ? Number(process?.env?.REACT_APP_MATOMO_IDSITE)
            : 9,
    disabled: false,
    heartBeat: {
        active: true,
        seconds: 10,
    },
    linkTracking: false,
    configurations: {
        disableCookies: true,
        // disableCookies: false,
        setSecureCookie: true,
        setRequestMethod: "POST",
    },
});

ReactDOM.render(
    <React.Fragment>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <MatomoProvider value={matomoInstance}>
                    <App />
                </MatomoProvider>
            </ThemeProvider>
        </Provider>
    </React.Fragment>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
