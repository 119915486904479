import { createSlice } from '@reduxjs/toolkit';

export const MAINSlice = createSlice({
    name: 'MAIN',
    initialState: {
        defaultData: localStorage.getItem('main.defaultData')
            ? JSON.parse(localStorage.getItem('main.defaultData'))
            : null,
        year: localStorage.getItem('main.year')
            ? JSON.parse(localStorage.getItem('main.year'))
            : null,
        range: localStorage.getItem('main.range')
            ? JSON.parse(localStorage.getItem('main.range'))
            : null,
        appRefs: {}
    },
    reducers: {
        setDefaultData: (state, action) => {
            localStorage.setItem('main.defaultData', JSON.stringify(action.payload))
            state.defaultData = action.payload;
        },
        setYear: (state, action) => {
            localStorage.setItem('main.year', JSON.stringify(action.payload))
            state.year = action.payload;
        },
        setRange: (state, action) => {
            localStorage.setItem('main.range', JSON.stringify(action.payload))
            state.range = action.payload;
        },
        setAppRefs: (state, action) => {
            state.appRefs = action.payload;
        },
    },
});

export const { setDefaultData, setYear, setRange, setAppRefs } = MAINSlice.actions;

export const getDefaultData = state => state.MAIN.defaultData;
export const getYear = state => state.MAIN.year;
export const getRange = state => state.MAIN.range;
export const getAppRefs = state => state.MAIN.appRefs;

export default MAINSlice.reducer;
