import { createSlice } from '@reduxjs/toolkit';

export const MAPSlice = createSlice({
    name: 'MAP',
    initialState: {
        mapData: localStorage.getItem('map.data')
        ? JSON.parse(localStorage.getItem('map.data'))
        : null,
    },
    reducers: {
        setMapData: (state, action) => {
            localStorage.setItem('map.data', JSON.stringify(action.payload))
            state.mapData = action.payload;
        },
    },
});

export const { setMapData } = MAPSlice.actions;

export const getMapData = state => state.MAP.mapData;

export default MAPSlice.reducer;
