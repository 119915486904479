import { createSlice } from '@reduxjs/toolkit';

export const CHARTSSlice = createSlice({
    name: 'CHARTS',
    initialState: {
        chartType: localStorage.getItem('chart.type')
            ? JSON.parse(localStorage.getItem('chart.type'))
            : 'bar',
    },
    reducers: {
        setChartType: (state, action) => {
            // localStorage.setItem('chart.type', JSON.stringify(action.payload))
            state.chartType = action.payload;
        },
    },
});

export const { setChartType } = CHARTSSlice.actions;

export const getChartType = state => state.CHARTS.chartType;

export default CHARTSSlice.reducer;
