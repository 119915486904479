import { createSlice } from "@reduxjs/toolkit";

export const TABLESlice = createSlice({
    name: "TABLE",
    initialState: {
        tableData: localStorage.getItem("table.data") ? JSON.parse(localStorage.getItem("table.data")) : null,
        currentSection: localStorage.getItem("table.section")
            ? JSON.parse(localStorage.getItem("table.section"))
            : {
                  id: 89,
                  name: "Wybrane dane statystyczne",
                  name_ang: "Selected statistical data",
              }, // : 0,
        currentIndicator: localStorage.getItem("table.indicator")
            ? JSON.parse(localStorage.getItem("table.indicator"))
            : // : null,
              {
                  2022: {
                      val: 5510612,
                      pre: 0,
                      fid: 1,
                  },
                  id: "89-531",
                  name: "Ludność [osoba] ",
                  name_ang: "Population [person] ",
              },
        areas: localStorage.getItem("table.areas") ? JSON.parse(localStorage.getItem("table.areas")) : null,
        loading: false,
    },
    reducers: {
        setTableData: (state, action) => {
            // localStorage.setItem('table.data', JSON.stringify(action.payload))
            state.tableData = action.payload;
        },
        setCurrentSection: (state, action) => {
            localStorage.setItem("table.section", JSON.stringify(action.payload));
            state.currentSection = action.payload;
        },
        setCurrentIndicator: (state, action) => {
            localStorage.setItem("table.indicator", JSON.stringify(action.payload));
            state.currentIndicator = action.payload;
        },
        setTableAreas: (state, action) => {
            localStorage.setItem("table.areas", JSON.stringify(action.payload));
            state.areas = action.payload;
        },
        setTableLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
});

export const { setTableData, setCurrentSection, setCurrentIndicator, setTableAreas, setTableLoading } =
    TABLESlice.actions;

export const getTableData = (state) => state.TABLE.tableData;
export const getCurrentSection = (state) => state.TABLE.currentSection;
export const getCurrentIndicator = (state) => state.TABLE.currentIndicator;
export const getTableAreas = (state) => state.TABLE.areas;
export const getTableLoading = (state) => state.TABLE.loading;

export default TABLESlice.reducer;
