import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

const fallbackLng = ["pl"];
const availableLanguages = ["en", "pl"];

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng,
        debug: false,
        whitelist: availableLanguages,
        lng: localStorage.getItem("lang") || "pl",
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
    });

export default i18n;
