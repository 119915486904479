import { configureStore } from '@reduxjs/toolkit';

import APPReducer from './APP'
import TREEReducer from './TREE'
import MAPReducer from './MAP'
import MAINReducer from './MAIN'
import TABLEReducer from './TABLE'
import CHARTSReducer from './CHARTS'

export default configureStore({
    reducer: {
        APP: APPReducer,
        TREE: TREEReducer,
        MAIN: MAINReducer,
        MAP: MAPReducer,
        TABLE: TABLEReducer,
        CHARTS:CHARTSReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false
        })
});
